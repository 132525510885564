.avatar-lobby-square {
    height: 55%;
    width: 55%;
    background-color: white;
    position: absolute;
    opacity: 1.0;
    border: theme('colors.border.blue') solid;

    display: flex;
    flex-direction: column;
}

.avatar-lobby-overlap {
    height: 55%;
    width: 55%;
    position: absolute;
    z-index: 1500;
}

.avatar-lobby-square-vip {
    height: 55%;
    width: 55%;
    background-color: white;
    position: absolute;
    opacity: 1.0;
    border: theme('colors.border.magenta') solid;
    z-index: 5;
}

.avatar-lobby-square-transparent {
    height: 55%;
    width: 55%;
    background-color: white;
    position: absolute;
    opacity: 0.6;
}

.avatar-lobby-container {
    @apply self-center;
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
}

.avatar-lobby {
    height: 100%;
    width: 100%;

    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border: transparent solid thick;
}

.avatar-lobby-clickable {
    cursor: pointer;
}

.avatar-lobby-checked {
    border: #1ef7ff solid thick;
}

.avatar-lobby-name-holder {
    position: absolute;
    bottom: 0;
    z-index: 5000;
}

.avatar-lobby-name {
    @apply text-xl py-0.5 text-center text-white bg-black z-50 leading-none flex justify-center items-center px-1;
}

.avatar-lobby-name-premium {
    @apply text-xl py-0.5 text-center text-black bg-white z-50 leading-none;
}

.avatar-lobby-img-holder {
    width: 100%;
    height: 100%;
    max-height: 96px;
    max-width: 96px;

    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
}

.avatar-lobby-img {
    width: 100%;
    height: 100%;
    max-height: 96px;
    max-width: 96px;
    animation: bbbb 2s linear infinite;
    animation-timing-function: steps(1, start);
}