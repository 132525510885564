/* autoprefixer grid: autoplace */

@font-face {
    font-family: 'FSEX302';
    src: local('FSEX302'), url(fonts/FSEX302.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Comic Sans';
    src: local('Comic Sans'), url(fonts/COMIC.TTF) format('truetype');
    font-display: swap;
}

html {
    @apply relative h-full;
}

body {
    @apply relative h-full m-0;
    /*height: inherit;*/


    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: FSEX302, serif;
    text-transform: uppercase;
    align-content: center;
}

#root {
    @apply relative h-full;
}

input {
    @apply uppercase;
    font-family: FSEX302, serif;
}

textarea {
    font-family: FSEX302, serif;
}

button {
    @apply uppercase bg-gray-drk cursor-pointer outline-none p-1;
    font-family: FSEX302, serif;
    box-shadow: 0.1rem 0.1rem theme('colors.gray.shadow');
}

button:disabled {
    @apply cursor-auto;
}

button:active {
    @apply shadow-none;
    transform: translateY(0.125rem) translateX(0.125rem);
}

button:focus-visible {
    outline: auto thick black;
}

input[type=submit] {
    @apply bg-gray-drk cursor-pointer outline-none;
}

input[type=submit]:disabled {
    @apply cursor-auto;
    cursor: auto;
}

input[type=submit]:focus-visible {
    outline: black solid medium;
}

input[type=submit]:active {
    @apply bg-gray-drk shadow-none;
    transform: translateY(0.1rem) translateX(0.1rem);
}

input[type=radio] {
    @apply bg-gray-drk cursor-pointer shadow-basic;
}

.fake-button {
    @apply bg-gray-drk shadow-basic p-4 text-center;
}

.holder {
    @apply min-h-full flex flex-col relative;
}

.app> {
    @apply min-h-0;
}

.app {
    @apply h-full flex-grow flex flex-col md:h-auto;
}

.app-centre {
    @apply flex-grow relative flex flex-col;
}


.text-emphasis {
    @apply underline italic font-bold;
}

/*.app-top-roomLabel {*/
/*    @apply h-12 flex flex-row px-1 leading-none text-3xl relative self-end bg-night-primary text-center z-10 border-b-0 min-h-[2rem] shadow-aestheticTop;*/
/*    flex-basis: 6rem;*/
/*    border: black medium solid;*/
/*    border-bottom-width: 0;*/
/*    !*min-height: 1.5rem;*!*/

/*    !*box-shadow: 5px 0 theme('colors.border.magenta'), 0 -5px theme('colors.border.blue');*!*/
/*}*/

/*.app-top-roomLabel span {*/
/*    @apply text-2xl text-center flex justify-center items-center leading-none;*/
/*}*/


.app-bottom-label {
    @apply px-2 flex justify-center items-center text-base bg-night-primary border-black border-2 border-t-0 text-center leading-none shadow-aestheticBottom;
    /*border: black medium solid;*/
    /*border-top-width: 0;*/
    min-height: 1rem;
    /*box-shadow: 5px 0 theme('colors.border.magenta'), 0 5px theme('colors.border.magenta');*/
}

/* Connection status indicator styles */
.status-indicator {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 1px solid #000;
    /* Add black border */
}

.status-indicator-connected {
    background-color: #4ade80;
    /* Green-500 */
}

.status-indicator-connecting {
    background-color: #facc15;
    /* Yellow-400 */
    animation: pulse 2s infinite;
}

.status-indicator-error {
    background-color: #ef4444;
    /* Red-500 */
}

.status-indicator-disconnected {
    background-color: #94a3b8;
    /* Slate-400 */
}

@keyframes pulse {
    0% {
        opacity: 0.6;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.6;
    }
}